<template>
    <div class="ButtonToShop">
        <a class="shop__item" :href="this.Link">
            <div class="shop__button button">BUY ON {{this.ShopName}}</div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "ButtonToShop",
        props:['Link', 'ShopName']
    }
</script>

<style scoped>

</style>