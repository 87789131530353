<template>
    <div class="menu">
        <nav class="menu__container">
            <router-link to="#products"><div class="menu__item"  v-on:click="scrollTo('#products')">PRODUCTS</div></router-link>
            <router-link to="#cats"><div class="menu__item"  v-on:click="scrollTo('#cats')">CATS</div></router-link>
            <router-link to="#about"><div class="menu__item"  v-on:click="scrollTo('#about')">ABOUT</div></router-link>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "menu",
        components:{
        },
        data() {
            return {};
        },
        methods:{
            scrollTo(goto){
                document.querySelector(goto).scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
                document.querySelector('.hidden-menu-ticker').checked = false;
                document.documentElement.style.overflow = "auto";
            },
        },
    }
</script>

<style>
    .menu__container{
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        font-family: 'BubblegumSans-Regular', cursive;
        font-size: 150%;
        text-decoration: none;
    }
    .menu__container a{
        text-decoration: none;
    }
    .menu__item{
        color: #e51b20;
        user-select: none;
        position: relative;
    }

    .menu__item:after{
        display: block;
        position: absolute;
        left: 0; /*изменить на right:0;, чтобы изменить направление подчёркивания */
        bottom:0;
        width: 0;/*задаём длинну линии до наведения курсора*/
        height: 2px; /*задаём ширину линии*/
        background-color: #e51b20;
        content: "";
        transition: width 0.3s ease-out; /*задаём время анимации*/
    }
    .menu__item:hover{
         cursor: pointer;
    }
    .menu__item:hover:after,
    .menu__item:focus:after {
        width: 100%; /*устанавливаем значение 100% чтобы ссылка подчёркивалась полностью*/
    }
    @media only screen and (max-width:992px) {
        .menu__container {
            grid-template-columns: 1fr;
            margin-top: 30px;
            grid-gap: 10px
        }

    }
</style>