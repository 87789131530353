<template>
    <div class="header">
            <div class="header__container">
                <div class="menu__burger-menu">
                    <input type="checkbox" id="hmt" class="hidden-menu-ticker" v-model="checked">
                    <label class="btn-menu" for="hmt">
                        <span class="first"></span>
                        <span class="second"></span>
                        <span class="third"></span>
                    </label>
                    <div class="header__logo-menu">
                        <div class="header__logo">
                            <img :src="require('../assets/logo.png')" alt="logo karate cats">
                        </div>
                        <vMenu/>
                    </div>
                </div>

                <div class="header__animate">
                    <img class="header__img-plank-left" :src="require('../assets/plank-left.png')" alt="plank" width="626" height="254">
                    <img class="header__img-plank-right" :src="require('../assets/plank-right.png')" alt="plank" width="626" height="254">
                    <img class="header__img-cat" :src="require('../assets/cat.png')" alt="karate cats" width="800" height="943">
                </div>
            </div>
    </div>
</template>

<script>
    import vMenu from "../components/menu"
    export default {
        name: "header",
        components:{
           vMenu
        },
        data(){
            return{
                checked: false,
            }
        },
        watch: {
            "checked": function(newVal) {
                if (newVal) {
                    document.documentElement.style.overflow = "hidden";
                    return;
                }
                document.documentElement.style.overflow = "auto";
            }
        },
        methods:{}
    }
</script>


<style>
    .header__container{
        height: 100%;
    }
    .header{
        width:100%;
        background: #f2dbd9;
        background-image: url("../assets/pattern.png");
        background-size: 20%;
        user-select: none;
        overflow: hidden;
    }

    .header__logo-menu{
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        max-width: 1020px;
        margin: auto;
        padding-top: 20px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 100;
    }
    .header__logo{
        text-align: center;
    }
    .header__logo > img{
        width:75%;
    }
    .header__animate{
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .header__img-cat{
        -webkit-transform: scale(0.5);
        -moz-transform: scale(0.5);
        -o-transform: scale(0.5);
        -ms-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transition: 1s ease-out;
        -moz-transition: 1s ease-out;
        -o-transition: 1s ease-out;
        -ms-transition: 1s ease-out;
        transition: 1s ease-out;
        width: auto;
        height: auto;
        max-width: 670px;
        max-height: 800px;
        margin: 30px;
    }
    .header__img-plank-left,.header__img-plank-right{
        position: absolute;
        z-index: 1;
        width: auto;
        height: auto;
        max-width: 300px;
    }
    .header__img-plank-left{
        transform-origin: right;
        top:40%;
    }
    .header__img-plank-right{
        top:40%;
        transform-origin: left;
    }
    .hidden-menu-ticker{
        display: none;
    }

    @keyframes leftPlank {
        from {
            transform: rotate(351deg) scale(0.1);
        }
        to{
            transform: translate(0,50%) rotate(291deg) scale(1);
            animation-timing-function: ease-out;
        }
    }

    @keyframes rightPlank {
        from {
            transform: rotate(351deg) scale(0.5);
        }
        to {
            transform: translate(120%, 10%) rotate(291deg) scale(1);
            animation-timing-function: ease-out;
            box-shadow: aliceblue;
        }
    }
    @media only screen and (max-height:260px) {
        .header__logo-menu{
            padding: 0;
        }
    }
    @media only screen and (max-width:992px) {
        .header__logo-menu{
            position: fixed;
            grid-template-rows: 1fr 5fr;
            grid-template-columns: 1fr;
            justify-items: center;
            align-items: start;
            background: bisque;
            height: 100%;
            margin: 0;
            width:100%;
            left:-100%;
            transition: left .2s;
            overflow-y: auto;
        }
        .header__logo > img{
            width:50%;
        }

        .btn-menu {
            padding: 5px;
            position: absolute;
            top: 5%;
            left: 7%;
            cursor: pointer;
            transition: left .23s;
            z-index: 101;
            width: 35px;
            -webkit-transform: translateZ(0);
            -webkit-backface-visibility: hidden;
        }
        .btn-menu span {
            display: block;
            height: 5px;
            background-color: #000000;
            margin: 4px 0 0;
            transition: all .1s linear .23s;
            position: relative;
        }
        .btn-menu span.first {
            margin-top: 0;
        }
        .hidden-menu-ticker:checked ~ .btn-menu span.first {
            -webkit-transform: rotate(45deg);
            top: 8px;
        }
        .hidden-menu-ticker:checked ~ .btn-menu span.second {
            opacity: 0;
        }
        .hidden-menu-ticker:checked ~ .btn-menu span.third {
            -webkit-transform: rotate(-45deg);
            top: -10px;
        }
        .hidden-menu-ticker:checked ~ .btn-menu {
            /* left: 160px;*/
        }
        .hidden-menu-ticker:checked ~.header__logo-menu {
            left: 0;
        }
    }
</style>