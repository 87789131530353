<template>
    <div class="shop_wrapper">
         <div class="shop__list-container">
             <button-to-shop :Link="shop.href"  :ShopName="shop.shopName" v-for="(shop) in shopsArr" v-bind:key="shop"/>
        </div>
    </div>
</template>

<script>
    import ButtonToShop from "./ButtonToShop";
    export default {
        name: "shops",
        components: {ButtonToShop},
        componets:{
            ButtonToShop
        },
        props:{
            shopsArr:{
                type:Array
            }
        },
    }
</script>

<style>
    .shop_wrapper{
        margin: 30px 0;
    }
    .shop_wrapper>p{
        margin-bottom: 15px;
        font-family: "Arial Black";
    }
    .block-title{
        font-size: 30px;
        font-family: 'BubblegumSans-Regular', cursive;
        text-align: center;
        padding: 20px 0;
        color:red;
    }

    .shop__list-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
        width: 70%;
        margin: auto;
    }
    .shop__list-container>*{
        flex: 33%;
        margin: 3px;
    }

    .shop__item > img{
        width: 100%;
        max-width: 500px;
        box-shadow: 1px 1px 5px 1px #00000033;
        margin-bottom: 10px;
    }
    .shop__item{
        text-decoration: none;
    }
    .button{
        padding: 10px;
        background: red;
        color: white;
        font-family: "Arial Black";
        white-space: nowrap;
        border-radius: 50px;
        font-size: 15px;
    }
    .button:hover{
        cursor: pointer;
        background: #940008;
    }

    @media only screen and (max-width:768px) {
        .shop__list-container{
            flex-direction: row;
            width: 100%;
        }
    }
</style>