<template>
    <div class="preloader__wrapper" v-show="preloaderBit">
        <div class="preloader__logo">
            <img :src="require('../assets/logo.png')" alt="logo karate cats" >
            <div>WELCOME<span class="loading">...</span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "preloader",
        props:{
            preloaderBit:{
                type:Boolean
            }
        },
    }
</script>

<style>
    .preloader__wrapper{
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 105;
        background: wheat;
        font-size: 20px;
        font-family: 'BubblegumSans-Regular', cursive;
        color: red;
    }
    .preloader__wrapper img{
        max-width: 150px;
    }
    .loading {
        font-weight: bold;
        display: inline-block;
        font-family: monospace;
        font-size: 27px;
        clip-path: inset(0 3ch 0 0);
        animation: l 1s steps(4) infinite;
    }

    @keyframes l {
        to {
            clip-path: inset(0 -1ch 0 0)
        }
    }
</style>