<template>
    <div class="products__wrapper" id="products">
        <h2 class="products__title block-title">PRODUCTS</h2>
        <div class="products__list-container">
            <div class="products__item" v-for="prod in products" v-bind:key="prod">
                <img :src="require('../assets/products_cover/'+prod.imgName)" :alt="prod.alt">
                <div class="products__info">
                    <div class="products__info-container">
                        <h2 class="product__title">{{prod.title}}</h2>
                    </div>
                    <div class="products__shops">
                        <vShops :shopsArr="prod.shops"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vShops from "./shops"
    export default {
        name: "products",
        components:{
            vShops
        },
        data(){
            return{
                products:{
                    product1:{
                        title:'Karate Cats OFFICIAL | Mini Wall Calendar 2025 (7x14 Inch)',
                        alt:'Karate Cats Wall calendar 2025 cover',
                        imgName:'KK-mini.jpg',
                        shops:[
                            {
                                shopName:'AMAZON.COM',
                                href:'https://amzn.to/4ayaIGB'
                            },
                            {
                                shopName:'CALENDARS.COM',
                                href:'https://www.calendars.com/shop/karate-cats-2025-mini-wall-calendar/202500010510'
                            },
                            {
                                shopName:'WALMART.COM',
                                href:'https://www.walmart.com/ip/seort/10127121021'
                            },
                            {
                                shopName:'QUILL.COM',
                                href:'https://www.quill.com/2025-browntrout-karate-cats-12-x-24-monthly-square-wall-calendar-9781975479831/cbs/55468368.html'
                            },
                            {
                                shopName:'STAPLES.COM',
                                href:'https://www.staples.com/product_24605402'
                            },
                            {
                                shopName:'STAPLESADVANTAGE.COM',
                                href:'https://www.staplesadvantage.com/product_24605402'
                            },
                            {
                                shopName:'EBAY.COM',
                                href:'https://www.ebay.com/itm/387308373739'
                            },
                            {
                                shopName:'MICHAELS.COM',
                                href:'https://www.michaels.com/product/369064492795838470'
                            },
                            {
                                shopName:'ETSY.COM',
                                href:'https://www.etsy.com/listing/1781217931'
                            },
                        ]
                    },
                    product2:{
                        title:'Karate Cats OFFICIAL | Square Wall Calendar | Plastic-Free (12x24 Inch)',
                        alt:'Karate Cats wall calendar 2025 cover',
                        imgName:'KK-SQ.jpg',
                        shops:[
                            {
                                shopName:'AMAZON.COM',
                                href:'https://amzn.to/4awnoh0'
                            },
                            {
                                shopName:'CALENDARS.COM',
                                href:'https://www.calendars.com/shop/karate-cats-2025-wall-calendar/202500010203'
                            },
                            {
                                shopName:'WALMART.COM',
                                href:'https://www.walmart.com/ip/seort/5406009391'
                            },
                            {
                                shopName:'QUILL.COM',
                                href:'https://www.quill.com/2025-browntrout-karate-cats-12-x-24-monthly-square-wall-calendar-9781975479831/cbs/55468368.html'
                            },
                            {
                                shopName:'STAPLES.COM',
                                href:'https://www.staples.com/product_24605410'
                            },
                            {
                                shopName:'STAPLESADVANTAGE.COM',
                                href:'https://www.staplesadvantage.com/product_24605410'
                            },
                            {
                                shopName:'EBAY.COM',
                                href:'https://www.ebay.com/itm/387310257621'
                            },
                            {
                                shopName:'MICHAELS.COM',
                                href:'https://www.michaels.com/product/369066133473345536'
                            },
                            {
                                shopName:'ETSY.COM',
                                href:'https://www.etsy.com/listing/1767285018'
                            },
                        ]
                    },
                },
            }
        }
    }
</script>

<style>
    .products__list-container{
        max-width: 1020px;
        width: 100%;
        margin: auto;
    }
    .products__item{
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        grid-gap: 20px;
        margin-bottom: 20px;
    }
    .products__item img{
        max-width: 100%;
        width:100%;
        /*box-shadow: 1px 1px 6px #1c1c1cb5;*/
    }
    .product__title{
        font-size: 30px;
    }
    .products__info{
        font-family: "Arial Black";
        display: grid;
       /* grid-template-rows: 1fr 3fr;*/
        grid-template-rows: 0fr 1fr;
        justify-items: center;

    }
    .products__info-container ul li{
        padding: 10px 0 0 25px;
        list-style: none;
        position: relative;
    }

    .products__info ul li:before{
        content: "✓";
        position: absolute;
        width:5px;
        height: 5px;
        color:green;
        left: 0;
    }

    .products__shops{
        margin: auto;
    }

    @media only screen and (max-width:1020px) {
        .products__list-container {
            width: 95%;
        }
    }

    @media only screen and (max-width:768px) {
        .products__item {
            grid-template-columns: 1fr;
        }
        .products__info {
            grid-template-rows: 0fr 1fr;
        }
        .products__shops{
            margin: auto;
        }
    }
</style>