<template>
    <div class="about__wrapper" id="about">
        <div class="about__title block-title">ABOUT</div>
        <div class="about__container">
            <img :src="require('../assets/red_cat2.jpg')" width="422" height="500">
            <div class="about__history"><p>It always amazed me how cats can embody agility and grace, speed and precise movement as if they were masters of martial arts.</p>
                <p>While playing with my cat Leova one evening and watching him masterfully attacking my hand, I came up with a cool idea. What if I could take some pictures of him, making a funny collage featuring my cat as a karate master?</p>
                <p>Once I made it, I showed my work to my friends, who laughed and loved it so much. Then one of my friends asked me to do something like that with his cat. At that point, I wondered if I could create a series of pictures with different karate stances and punches.</p> <p>So I took one more picture, followed by a second one, and then another one. That's how the project was born, and I'll be happy if it makes you smile or prompts you to practice karate.</p>
            </div>
         </div>
    </div>
</template>

<script>
    export default {
        name: "about"
    }
</script>

<style>
    .about__container > img{
        width: auto;
        height: auto;
    }
    .about__container{
        max-width: 1020px;
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
        justify-items: center;
        user-select: none;
    }
    .about__history{
        font-family: 'BubblegumSans-Regular', cursive;
        font-size: 18px;
        line-height: 1.5em
    }

    @media only screen and (max-width:960px) {
        .about__container{
            grid-template-columns: 1fr 2fr;
        }
    }
    @media only screen and (max-width:768px) {
        .about__container{
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 90%;
        }
        .about__container img{
            display: none;
        }
    }

</style>