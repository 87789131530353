<template>
    <div class="gallery__wrapper">
        <div class="mini-gallery__title block-title" id="cats">OUR CATS</div>
        <template v-if="galleryShow">
            <div class="gallery__viewer">
                <div class="gallery__viewer-photoSpace">
                    <div class="gallery__viewer-leftBtn"
                         v-on:click="previousPhoto(galleryItem.id)"
                         v-if="galleryItem.id!=0"
                    ></div>
                    <div class="gallery__viewer-rightBtn"
                         v-on:click="nextPhoto(galleryItem.id)"
                         v-if="galleryItem.id+1!=photos.length"
                    ></div>
                    <img :src="require('../assets/gallery/'+ galleryItem.src)">
                    <div class="gallery__viewer-info">
                        <p class="gallery__viewer-name">{{galleryItem.name}}</p>
                        <p class="gallery__viewer-pose">{{galleryItem.pose}}</p>
                    </div>
                </div>
                <div class="gallery__viewer-close close-btn" v-on:click="galleryClose"></div>
            </div>
        </template>

        <div class="gallery__container">
            <div class="gallery__item"
                 v-for="(photo,index) in photos"
                 :key="photo"
                 :style="{gridArea: photo.alias}"
                 v-on:click="galleryViewer(index,photo.catName,photo.pose,photo.imgSrc)">
                <img :src="require('../assets/gallery/'+ photo.imgSrc)"
                     :alt="photo.pose"/>
                <div class="gallery__item-info">
                    <div class="gallery__item-name">{{photo.catName}}</div>
                    <div class="gallery__item-pose">{{photo.pose}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "gallery",
        data(){
            return{
                galleryShow:false,
                galleryItem:{
                    id:'',
                    name:'',
                    pose:'',
                    src:''
                },
                photos:[
                    {catName:'Leova',pose:'Agura',imgSrc:"1.jpg",alias:'img1'},
                    {catName:'Oskar',pose:'Junzuki  Tsukkomidachi',imgSrc:"2.jpg",alias:'img2'},
                    {catName:'Liza',pose:'Split-leg',imgSrc:"3.jpg",alias:'img3'},
                    {catName:'Jack',pose:'Shuto Uke',imgSrc:"4.jpg",alias:'img4'},
                    {catName:'Pixel',pose:'Choku Tsuki',imgSrc:"5.jpg",alias:'img5'},
                    {catName:'Sima',pose:'Shuto',imgSrc:"6.jpg",alias:'img6'},
                    {catName:'Odisei',pose:'Zenkutsu Dachi',imgSrc:"7.jpg",alias:'img7'},
                    {catName:'Kitty',pose:'Neko Ashi Dachi',imgSrc:"8.jpg",alias:'img8'},
                    {catName:'Valli',pose:'Tsuruashi Dachi',imgSrc:"9.jpg",alias:'img9'},
                    {catName:'Musya',pose:'Mae Tobi Geri',imgSrc:"10.jpg",alias:'img10'},
                    {catName:'Rone',pose:'Koukutsu Dachi',imgSrc:"11.jpg",alias:'img11'},
                    {catName:'Ozzi',pose:'Mawashi Hiza Geri',imgSrc:"12.jpg",alias:'img12'},
                    {catName:'Sema',pose:'Tameshiwari',imgSrc:"13.jpg",alias:'img13'},
                    {catName:'Nero',pose:'Hachi No Kaiten',imgSrc:"14.jpg",alias:'img14'},
                    {catName:'Kitty',pose:'Bojutsu No Jodan Uchi',imgSrc:"15.jpg",alias:'img15'},
                    {catName:'Alisa',pose:'Soto Uke',imgSrc:"16.jpg",alias:'img16'},
                    {catName:'Balu',pose:'Nukite',imgSrc:"17.jpg",alias:'img17'},
                    {catName:'Albus',pose:'Yoko Sosoku Geri',imgSrc:"18.jpg",alias:'img18'},
                    {catName:'Simba',pose:'Gedan Bari',imgSrc:"19.jpg",alias:'img19'},
                    {catName:'Jack',pose:'Kake Ashi Dachi',imgSrc:"20.jpg",alias:'img20'},
                    {catName:'Magnum',pose:'Hiza Ganmen Geri',imgSrc:"21.jpg",alias:'img21'},
                    {catName:'Murk',pose:'Tobi Yoko Geri',imgSrc:"22.jpg",alias:'img22'},
                    {catName:'Nala',pose:'Yoko Geri',imgSrc:"23.jpg",alias:'img23'},
                    {catName:'Sheikh',pose:'Jodan Age Hiji Ate',imgSrc:"24.jpg",alias:'img24'},
                    {catName:'Viva Lucia',pose:'Jodan Mae Geri',imgSrc:"25.jpg",alias:'img25'},
                    {catName:'Sphinx',pose:'Kiza',imgSrc:"26.jpg",alias:'img26'},
                    {catName:'Albus',pose:'Ashi Barai',imgSrc:"27.jpg",alias:'img27'},
                    {catName:'John',pose:'Yoko Tzuki',imgSrc:"28.jpg",alias:'img28'},
                    {catName:'Valli',pose:'Mae Geri',imgSrc:"29.jpg",alias:'img29'},
                    {catName:'Jack',pose:'Yama Tzuki',imgSrc:"30.jpg",alias:'img30'},
                    {catName:'Timka',pose:'Mae Tobi Geri',imgSrc:"31.jpg",alias:'img31'},
                    {catName:'Magnum',pose:'Shiko Dachi',imgSrc:"32.jpg",alias:'img32'},
                    {catName:'Tomyam',pose:'Gankaku Dachi',imgSrc:"33.jpg",alias:'img33'},
                    {catName:'Sonya',pose:'Joudan Mawashi Geri',imgSrc:"34.jpg",alias:'img34'},
                    {catName:'Kitty',pose:'Sanchin Dachi',imgSrc:"35.jpg",alias:'img35'},
                    {catName:'Linda',pose:'Koukutsu Dachi',imgSrc:"36.jpg",alias:'img36'},
                    {catName:'Leo',pose:'Nidan Geri',imgSrc:"37.jpg",alias:'img37'},
                    {catName:'Nala',pose:'Tobi Mawashi Geri',imgSrc:"38.jpg",alias:'img38'},
                    {catName:'Bucksik',pose:'Gedan Shotei Mawashi Uke',imgSrc:"39.jpg",alias:'img39'},
                ],
            }
        },
        mounted: function () {
            this.galleryCreate();
            window.scrollTo(0,0);
            window.addEventListener("resize", this.galleryCreate);
        },
        beforeUnmount () {
            window.removeEventListener("resize", this.galleryCreate);
        },
        methods: {
            galleryCreate() {
                let clientH=document.documentElement.clientHeight;
                if (this.galleryShow){
                    this.$nextTick(()=>{
                        let galleryImgH=document.querySelector(".gallery__viewer-photoSpace").clientHeight;
                        let newImgHeight=clientH-document.querySelector('.gallery__viewer-info').clientHeight;
                        if(clientH<=galleryImgH){
                            document.querySelector(".gallery__viewer-photoSpace > img").style.width=newImgHeight+"px";
                        }
                        else{
                            document.querySelector(".gallery__viewer-photoSpace > img").style.width="auto";
                        }
                    })

                }

                var tplSize = Math.ceil((document.documentElement.clientWidth - 500) / 250);
                // var rows = Math.ceil(this.photos.length/(tplSize));
                var rowsCount=2;
                var bigImg = '';
                if (tplSize <= 1) {
                    tplSize = 1;
                }
                for (let l = 0; l < this.photos.length; l++) {
                    if (bigImg.indexOf('.') == -1) {
                        if (l % 2 == 0) {
                            bigImg += ('"' + this.photos[l].alias + ' ' + this.photos[l].alias + (' . ').repeat(tplSize) + '" ').repeat(2);
                        } else if((tplSize+2)+rowsCount>=this.photos.length &&tplSize>=4){
                            bigImg+=('"' +this.photos[l].alias + ' '+(' . ').repeat(tplSize+1)+'"');
                        }
                        else {
                            bigImg += ('"' + (' . ').repeat(tplSize) + this.photos[l].alias + ' ' + this.photos[l].alias + '" ').repeat(2);
                        }
                        rowsCount=rowsCount+2;
                    } else {
                        bigImg = bigImg.replace('.', this.photos[l].alias);
                        rowsCount++;
                    }
                }
                document.querySelector('.gallery__container').style.gridTemplateAreas = bigImg;
            },
            galleryViewer(index,catName,pose,imgSrc){
                this.galleryShow=true;
                this.galleryItem.id=index;
                this.galleryItem.name=catName;
                this.galleryItem.pose=pose;
                this.galleryItem.src=imgSrc;
                this.galleryCreate();
            },
            galleryClose(){
                this.galleryShow=false;
            },
            nextPhoto(index){
                if(this.photos[index+1]){
                    this.galleryItem.id=index+1;
                    this.galleryItem.name=this.photos[index+1].catName;
                    this.galleryItem.pose=this.photos[index+1].pose;
                    this.galleryItem.src=this.photos[index+1].imgSrc;
                }

            },
            previousPhoto(index){
                if(this.photos[index-1]){
                    this.galleryItem.id=index-1;
                    this.galleryItem.name=this.photos[index-1].catName;
                    this.galleryItem.pose=this.photos[index-1].pose;
                    this.galleryItem.src=this.photos[index-1].imgSrc;
                }
            }
        },
        watch: {
            "galleryShow": function(newVal) {
                if (newVal) {
                    document.documentElement.style.overflow = "hidden";
                    return;
                }
                document.documentElement.style.overflow = "auto";
            }
        },
    }
</script>

<style>
    img{
        max-width: 100%;
        max-height: 100%;
    }
    .gallery__wrapper{
        width: 100%;
        margin: auto;
        user-select: none;
    }
    .gallery__container{
        display: grid;
        grid-auto-columns: 1fr;
    }
    .gallery__item>img{
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
    .gallery__item{
        position: relative;
        overflow: hidden;
    }
    .gallery__item-info{
        position: absolute;
        top:0;
        padding-top: 45%;
        height: 100%;
        width:100%;
        text-align: center;
        background: #000;
        font-size: 20px;
        font-family: 'BubblegumSans-Regular', cursive;
        color: white;
        opacity: 0;
    }
    .gallery__item-info:hover{
        transition: .6s;
        opacity: .9;
        cursor: pointer;
    }
    .gallery__viewer{
        display: grid;
        grid-template-columns:1fr;
        justify-items: center;
        align-items: center;
        position: fixed;
        top:0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 101;
        color: white;
    }
    .gallery__viewer-photoSpace{
        position: relative;
    }

    .gallery__viewer-photoSpace img{
        max-height: 500px;
    }
    .gallery__viewer-info{
        text-align: center;
        padding: 20px 0;
        font-size: 20px;
        font-family: 'BubblegumSans-Regular', cursive;
    }
    .gallery__viewer-leftBtn:hover,.gallery__viewer-rightBtn:hover{
        cursor: pointer;
    }
    .gallery__viewer-leftBtn{
        left:0;
    }
    .gallery__viewer-rightBtn{
        right:0;
    }
    .gallery__viewer-leftBtn,.gallery__viewer-rightBtn{
        height: 100%;
        width: 50%;
        position: absolute;
        user-select: none;
    }
    .gallery__viewer-leftBtn:after,.gallery__viewer-rightBtn:after{
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-top: 3px solid white;
        top: 40%;
        color:white;
        user-select: none;
    }
    .gallery__viewer-leftBtn:after{
        border-left: 3px solid white;
        transform: rotate(-45deg);
        left: 10%;
    }
    .gallery__viewer-rightBtn:after{
        border-right: 3px solid white;
        transform: rotate(45deg);
        right: 10%;
    }

    .close-btn{
        position: absolute;
        right:0;
        top: 0;
        width: 50px;
        height: 50px;
    }
    .close-btn:before,.close-btn:after{
        content: "";
        position: absolute;
        right: 25%;
        top: 40%;
        width: 24px;
        height:5px;
        background: white;
    }
    .close-btn:before{
        transform: rotate(45deg);
    }
    .close-btn:after{
        transform: rotate(-45deg);
    }
    .close-btn:hover{
        cursor: pointer;
        background: #121212;
    }
</style>