<template>
    <div class="footer__wrapper">
        <div class="footer__info">Copyright © <a href="https://www.facebook.com/sergey.yakovenko.18"> Sergey Yakovenko</a> © karate-kats.com 2021-2025</div>
    </div>
</template>

<script>
    export default {
        name: "footer"
    }
</script>

<style>
.footer__wrapper{
    width: 100%;
    background: #f2dbd9;
    margin-top: 20px;
}
.footer__info{
    color: black;
    text-align: center;
    font-family: 'BubblegumSans-Regular', cursive;
    font-size: 15px;
    line-height: 30px;
}
    .footer__info a{
        text-decoration: none;
        color:#ff0000;
    }
</style>