<template>
  <div class="app-wrapper">
    <vPreloader :preloaderBit="preloader"/>
    <div class="app-wrapper__content">
      <vHeader/>
      <vProducts/>
      <!--<vGalleryMini/>-->
      <vGallery/>
      <vAbout/>
      <vFooter/>
    </div>
  </div>
</template>

<script>

  import vPreloader from "@/components/preloader"
import vHeader from "@/components/header"
import vProducts from "@/components/products"
//import vGalleryMini from "@/components/gallery-mini"
  import vGallery from "@/components/gallery"

import vAbout from "@/components/about"
import vFooter from "@/components/footer"

export default {
  name: "Home",
  components:{
    vHeader,
    vProducts,
    //vGalleryMini,
    vGallery,
    vAbout,
    vFooter,
    vPreloader,
  },
  data() {
    return {
      credentials: null,
      preloader:true,
    };
  },
  computed: {},
  mounted: function () {
    window.addEventListener('load', () => {
      this.preloader=false;
      this.loadPage();
      this.animationStart();
    })
    window.onpopstate =  (()=>{
      this.preloader=false;
      document.documentElement.style.overflow = "auto";
      this.loadPage();
      this.animationStart();
    });
    window.addEventListener("resize", this.loadPage);
  },
  beforeUnmount () {
    window.removeEventListener("resize", this.loadPage);
  },
  methods: {
    scrollTo(){
     const anchors = document.querySelectorAll('a[href^="#"]')
// Цикл по всем ссылкам
      for(let anchor of anchors) {
        anchor.addEventListener("click", function(e) {
          e.preventDefault() // Предотвратить стандартное поведение ссылок
          // Атрибут href у ссылки, если его нет то перейти к body (наверх не плавно)
          const goto = anchor.hasAttribute('href') ? anchor.getAttribute('href') : 'body'
          // Плавная прокрутка до элемента с id = href у ссылки
          document.querySelector(goto).scrollIntoView({
            behavior: "smooth",
            block: "start"
          })
        })
      }
    },
    loadPage(){
      let img = document.querySelector('.header__img-cat');
      let imgPlankLeft = document.querySelector('.header__img-plank-left');
      let imgPlankRight = document.querySelector('.header__img-plank-right');

      const maxWidthImg = parseInt(getComputedStyle(img).maxWidth);
      const maxHeightImg = parseInt(getComputedStyle(img).maxHeight);
      const marginImg = (parseInt(getComputedStyle(img).marginTop)*2);

      let clientWidth=document.documentElement.clientWidth;
      let clientHeight=document.documentElement.clientHeight;

      if (clientWidth<clientHeight && clientWidth<maxWidthImg){
        img.style.width=(clientWidth-marginImg)+'px';
        img.style.height='auto';
      }
      else if(clientWidth>=clientHeight && clientHeight<maxHeightImg){
        img.style.height=(clientHeight-marginImg)+'px';
        img.style.width='auto';
      }
      else {
        img.style.width='670px';
        img.style.height='800px';
      }
      imgPlankLeft.style.width=(img.clientWidth/2)+'px';
      imgPlankLeft.style.top=((img.clientHeight+30)/2.5)+'px';
      imgPlankLeft.style.left=img.offsetLeft-imgPlankLeft.clientWidth/8+'px';

      imgPlankRight.style.width=(img.clientWidth/2)+'px';
      imgPlankRight.style.top=((img.clientHeight+30)/2.5)+'px';
      imgPlankRight.style.right=img.offsetLeft+imgPlankRight.clientWidth/2+'px';
    },
    animationStart(){
      const time = 1500;
      const step = 1;
      const num = 6;
      let n = 40;
      let img = document.querySelector('.header__img-cat');
      let e = document.querySelector('.header__container');
      let t = Math.round(time / (n / step));
      let interval = setInterval(() => {
        n = n - step;
        if (n == num) {
          img.style.transition='0s';
          clearInterval(interval);
        }
        e.style.backgroundImage='repeating-conic-gradient(#fff0 0 5deg, #ffffff69 '+n+'deg 20deg)';
      }, t);
      img.style.transform='scale(1)';
      let imgPlankLeft = document.querySelector('.header__img-plank-left');
      let imgPlankRight = document.querySelector('.header__img-plank-right');
      imgPlankLeft.style.animation='leftPlank 1.2s forwards';
      imgPlankRight.style.animation='rightPlank 1.2s forwards';
    },
  },
};
</script>